<template>
    <div class="cs-visual-narrative-segment-block cs-block" :class="`${styleClass}${hasBg}`">
        <div class="cs-block-base" :class="rootClasses">
            <div class="container">
                <div class="cs-row">
                    <div class="cs-col">
                        <div>
                            <div class="text">
                                <h2 class="cs-title">
                                    <ColorSplit :data="data.title" />
                                </h2>
                                <h3 class="cs-sub-title" v-if="data.subTitle">{{data.subTitle}}</h3>
                                <span class="cs-text" v-html="data.text"></span>
                                <div v-if="data.buttons && ((typeof data.buttons == 'object' && Object.keys(data.buttons).length) || (Array.isArray(data.buttons) && data.buttons.length))" class="block-buttons">
                                    <Buttons :data="data.buttons" :cs_style="data.style" />    
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="cs-col">
                        <div class="image">
                            <CSMedia :data="data.image" :support="['image', 'video']" />
                        </div>
                    </div>

                </div> 
            </div>
        </div>
    </div>
</template>

<script>
    import {computed} from '../libs/common-fn';

    export default {
        name: "VisualNarrativeSegment",
        components: {
            ColorSplit: () => import("../components/CSUtilsColorSplit.vue"),
            Buttons: () => import("../components/CSUtilsButtons.vue"),
            CSMedia: () => import("../components/CSMedia.vue")
        },
        props: {
            data: {
                type: Object,
            default: () => {},
            },
        },
        data() {
            return {
                blocks: []
            };
        },
        computed: {
            ...computed('VisualNarrativeSegment'),
            hasBg() {
                if (this.data.style == 'color') return ' has-background';

                return '';
            }
        },
        methods: {},
        created() {}
    };
</script>
<style lang="scss" scoped>
    @import "../styles/main.scss";

    .cs-block.cs-visual-narrative-segment-block{



        &.cs-style- {
            &color {
                @include cs-block-color;
                .cs-block-base .text{
                    color:white;
                }
                h3{
                    color: $sub_title_color;
                }
            
            }

            &light {
                @include cs-block-light;
                .text {
                    h3 {
                        color: $sub_title_color_light;
                    }
                }
            }

            &dark {
                @include cs-block-dark;
                .cs-block-base .text{
                    h3{
                        color: $sub_title_color_dark;
                    }
                }
            }
        }


        .cs-block-base{
            .image {
                ::v-deep {
                    img,
                    video {
                        width: 100%;
                        object-fit: cover;
                        object-position: center;
                        height: auto;
                        
                        @media (max-width:992px){
                            margin-bottom:30px;
                        }
                    }
                }
            } 
            .text{
                h3 {
                    font-size: 20px;
                    font-weight: 400;
                    padding: 10px 0;
                }
                &>span{
                    font-weight: 300;
                    letter-spacing: 0.3px;
                    line-height: 1.7rem;
                }
            }


            .cs-col>div{
                height: 100%;
                display: flex;
                justify-content: center;
                align-items: center;
            }
        }
    }
</style>
